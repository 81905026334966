/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.411+20240807T073820Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CapabilityTypeDto } from '../model/capabilityTypeDto';
import { CreateFacilityDto } from '../model/createFacilityDto';
import { EditFacilityDto } from '../model/editFacilityDto';
import { FacilityDetailDto } from '../model/facilityDetailDto';
import { FacilityDto } from '../model/facilityDto';
import { FacilityMapDto } from '../model/facilityMapDto';
import { ZoneDto } from '../model/zoneDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FacilityApiService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Edit zone
     * 
     * @param facilityId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addZoneToFacility(facilityId: number, body?: ZoneDto, observe?: 'body', reportProgress?: boolean): Observable<FacilityDto>;
    public addZoneToFacility(facilityId: number, body?: ZoneDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FacilityDto>>;
    public addZoneToFacility(facilityId: number, body?: ZoneDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FacilityDto>>;
    public addZoneToFacility(facilityId: number, body?: ZoneDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling addZoneToFacility.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FacilityDto>('put',`${this.basePath}/api/facility/${encodeURIComponent(String(facilityId))}/zone`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new facility
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFacility(body?: CreateFacilityDto, observe?: 'body', reportProgress?: boolean): Observable<FacilityDto>;
    public createFacility(body?: CreateFacilityDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FacilityDto>>;
    public createFacility(body?: CreateFacilityDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FacilityDto>>;
    public createFacility(body?: CreateFacilityDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FacilityDto>('post',`${this.basePath}/api/facility`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit facility
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editFacility(body?: EditFacilityDto, observe?: 'body', reportProgress?: boolean): Observable<FacilityDto>;
    public editFacility(body?: EditFacilityDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FacilityDto>>;
    public editFacility(body?: EditFacilityDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FacilityDto>>;
    public editFacility(body?: EditFacilityDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FacilityDto>('put',`${this.basePath}/api/facility`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all facilities
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFacilities(observe?: 'body', reportProgress?: boolean): Observable<Array<FacilityDto>>;
    public getAllFacilities(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FacilityDto>>>;
    public getAllFacilities(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FacilityDto>>>;
    public getAllFacilities(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FacilityDto>>('get',`${this.basePath}/api/facility/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all facilities with longitude and latitude
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFacilitiesMapOverview(observe?: 'body', reportProgress?: boolean): Observable<Array<FacilityMapDto>>;
    public getAllFacilitiesMapOverview(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FacilityMapDto>>>;
    public getAllFacilitiesMapOverview(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FacilityMapDto>>>;
    public getAllFacilitiesMapOverview(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FacilityMapDto>>('get',`${this.basePath}/api/facility/map`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all facility types
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFacilityTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getAllFacilityTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getAllFacilityTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getAllFacilityTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/facility/type`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all facility types
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCapabilities(observe?: 'body', reportProgress?: boolean): Observable<Array<CapabilityTypeDto>>;
    public getCapabilities(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CapabilityTypeDto>>>;
    public getCapabilities(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CapabilityTypeDto>>>;
    public getCapabilities(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CapabilityTypeDto>>('get',`${this.basePath}/api/facility/capabilities`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all facilities
     * 
     * @param facilityTypes 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFacilities(facilityTypes?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<FacilityDto>>;
    public getFacilities(facilityTypes?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FacilityDto>>>;
    public getFacilities(facilityTypes?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FacilityDto>>>;
    public getFacilities(facilityTypes?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (facilityTypes) {
            facilityTypes.forEach((element) => {
                queryParameters = queryParameters.append('facilityTypes', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FacilityDto>>('get',`${this.basePath}/api/facility`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the requested facility
     * 
     * @param facilityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFacility(facilityId: number, observe?: 'body', reportProgress?: boolean): Observable<FacilityDto>;
    public getFacility(facilityId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FacilityDto>>;
    public getFacility(facilityId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FacilityDto>>;
    public getFacility(facilityId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling getFacility.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FacilityDto>('get',`${this.basePath}/api/facility/${encodeURIComponent(String(facilityId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the requested facility details
     * 
     * @param facilityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFacilityDetail(facilityId: number, observe?: 'body', reportProgress?: boolean): Observable<FacilityDetailDto>;
    public getFacilityDetail(facilityId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FacilityDetailDto>>;
    public getFacilityDetail(facilityId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FacilityDetailDto>>;
    public getFacilityDetail(facilityId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling getFacilityDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FacilityDetailDto>('get',`${this.basePath}/api/facility/${encodeURIComponent(String(facilityId))}/detail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlinks the zone from the facility
     * 
     * @param facilityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkZone(facilityId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unlinkZone(facilityId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unlinkZone(facilityId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unlinkZone(facilityId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling unlinkZone.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/facility/${encodeURIComponent(String(facilityId))}/unlink`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the capability of the facility
     * 
     * @param facilityId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCapability(facilityId: number, body?: Array<CapabilityTypeDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCapability(facilityId: number, body?: Array<CapabilityTypeDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCapability(facilityId: number, body?: Array<CapabilityTypeDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCapability(facilityId: number, body?: Array<CapabilityTypeDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (facilityId === null || facilityId === undefined) {
            throw new Error('Required parameter facilityId was null or undefined when calling updateCapability.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/facility/capabilities/${encodeURIComponent(String(facilityId))}/capability`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
