export * from './capsuleApi.service';
import { CapsuleApiService } from './capsuleApi.service';
export * from './capsuleTypeApi.service';
import { CapsuleTypeApiService } from './capsuleTypeApi.service';
export * from './dashboardApi.service';
import { DashboardApiService } from './dashboardApi.service';
export * from './dhlImporterApi.service';
import { DhlImporterApiService } from './dhlImporterApi.service';
export * from './error.service';
import { ErrorService } from './error.service';
export * from './exporterApi.service';
import { ExporterApiService } from './exporterApi.service';
export * from './facilityApi.service';
import { FacilityApiService } from './facilityApi.service';
export * from './iotApi.service';
import { IotApiService } from './iotApi.service';
export * from './propertiesApi.service';
import { PropertiesApiService } from './propertiesApi.service';
export * from './shipmentApi.service';
import { ShipmentApiService } from './shipmentApi.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './zoneApi.service';
import { ZoneApiService } from './zoneApi.service';
export const APIS = [CapsuleApiService, CapsuleTypeApiService, DashboardApiService, DhlImporterApiService, ErrorService, ExporterApiService, FacilityApiService, IotApiService, PropertiesApiService, ShipmentApiService, UserService, ZoneApiService];
