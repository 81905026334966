import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserDto, FacilityDto } from '@api';
import { NAVIGATION } from '@common/constant/navigation';
import { AuthService } from '@common/service/auth.service';
import { FacilityService } from '@common/service/facility.service';
import { AuthStore } from '@common/store/auth.store';
import { FacilityStore } from '@common/store/facility.store';
import { RoutingService } from '@common/service/routing.service';
import { FormControl, FormGroup } from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {

  public facilities: FacilityDto[] | undefined;
  public selectedFacilityFormGroup = new FormGroup({ facility: new FormControl() });
  public currentUser$: Observable<CurrentUserDto | undefined>;


  public constructor(
    private readonly router: Router,
    private readonly authStore: AuthStore,
    private readonly authService: AuthService,
    private readonly facilityService: FacilityService,
    private readonly facilityStore: FacilityStore,
    public readonly routingService: RoutingService,
  ) {
      this.currentUser$ = this.authService.currentUser$;
  }

  public async ngOnInit(): Promise<void> {
    this.facilityService.getAllServiceCenters().subscribe(facilities => {
      this.facilities = facilities;
    });

    if (this.getSelectedFacility) {
      this.selectedFacilityFormGroup.get('facility')?.setValue(this.getSelectedFacility);
    }
  }

  public containsFacilityMultipleEntries(): boolean {
    return this.facilities !== undefined && this.facilities.length >= 1;
  }

  public async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate([NAVIGATION.Root]);
  }

  public async goToDashboard(): Promise<void> {
    await this.routingService.navigateToHome();
  }

  public async goToUserPage(): Promise<void> {
    await this.router.navigate([NAVIGATION.User]);
  }

  public get getSelectedFacility(): FacilityDto | undefined {
    return this.facilityStore.getSelectedFacility();
  }

  public async setSelectedFacility(): Promise<void> {
    this.facilityStore.setSelectedFacility(this.selectedFacilityFormGroup.getRawValue().facility);
    window.document.location.reload();
  }
}
