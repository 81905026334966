import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Url} from '@common/constant/url';
import {AuthService} from '@common/service/auth.service';
import {AccessControlService} from '@common/service/auth.service.access-control-service';
import {AuthStore} from '@common/store/auth.store';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';

@Component({
    selector: 'app-sub-navbar',
    templateUrl: './sub-navbar.component.html',
    styleUrls: ['./sub-navbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SubNavbarComponent implements OnInit {
    public items: MenuItem[] = [];

    public constructor(
        private readonly router: Router,
        private readonly authStore: AuthStore,
        private readonly translate: TranslateService,
        private readonly accessControlService: AccessControlService,
        public readonly authService: AuthService,
    ) {}

    public async ngOnInit(): Promise<void> {
        this.setDashBoardMenu();
        this.setScanMenu();
        this.setFacilityMenu();
        this.setShipmentMenu();
        this.setConsigneeMenu();
        this.setCapsuleMenu();
        this.setUserManagementMenu();
        this.setApiManagementMenu();
        this.setHandbook();
    }

    private setHandbook(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-USER-MANUAL'),
            icon: 'pi pi-fw pi-question-circle',
            url: '/handbook.html',
        });
    }

    private setDashBoardMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-DASHBOARD'),
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: Url.dashboardUrl,
            visible: this.accessControlService.dashboard(),
        });
    }

    private setScanMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-SCAN'),
            icon: 'pi pi-fw pi-qrcode',
            visible: this.accessControlService.scanMenu(),
            items: [
                {
                    label: this.translate.instant('SUB-NAVBAR-TRANSFERSHIPMENT'),
                    routerLink: Url.transfershipmentScanUrl,
                    visible: this.accessControlService.transfershipmentScan(),
                },
                {
                    label: this.translate.instant('SUB-NAVBAR-CUSTOMER-SCAN'),
                    routerLink: Url.customerScanUrl,
                    visible: this.accessControlService.customerScan(),
                },
                {
                    label: this.translate.instant('SUB-NAVBAR-SERVICE-POINT-SCAN'),
                    routerLink: Url.servicePointScanUrl,
                    visible: this.accessControlService.servicePointScan(),
                },
            ],
        });
    }

    private setFacilityMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-FACILITY'),
            icon: 'pi pi-fw pi-home',
            items: [
                {
                    label: this.translate.instant('SUB-NAVBAR-FACILITY'),
                    routerLink: Url.facilityUrl,
                },
                {
                    label: this.translate.instant('SUB-NAVBAR-FACILITY-MANAGEMENT'),
                    routerLink: Url.facilityManagementUrl,
                    visible: this.accessControlService.isAdmin(),
                },
            ],
        });
    }

    private setShipmentMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-SHIPMENT'),
            icon: 'pi pi-fw pi-truck',
            routerLink: Url.shipmentUrl,
        });
    }

    private setConsigneeMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-CONSIGNEE'),
            icon: 'pi pi-fw pi-truck',
            routerLink: Url.consigneeCycleManagement,
            visible: this.accessControlService.isAdmin(),
        });
    }

    private setCapsuleMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-CAPSULES'),
            icon: 'pi pi-fw pi-box',
            items: [
                {
                    label: this.translate.instant('SUB-NAVBAR-CAPSULE'),
                    routerLink: Url.capsuleUrl,
                },
                {
                    label: this.translate.instant('SUB-NAVBAR-CAPSULE-MANAGEMENT'),
                    routerLink: Url.capsuleManagementUrl,
                    visible: this.accessControlService.isAdmin(),
                },
            ],
        });
    }

    private setUserManagementMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-USER-MANAGEMENT'),
            icon: 'pi pi-fw pi-user-edit',
            routerLink: Url.userManagementUrl,
            visible: this.accessControlService.isAdmin(),
        });
    }

    private setApiManagementMenu(): void {
        this.items.push({
            label: this.translate.instant('SUB-NAVBAR-API-MANAGEMENT'),
            icon: 'pi pi-fw pi-home',
            routerLink: Url.apiManagementUrl,
            visible: this.accessControlService.isAdmin(),
        });
    }
}
