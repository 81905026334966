import {Component, Input} from '@angular/core';
import {FacilityDto} from '@api';

@Component({
    selector: 'facility-link',
    templateUrl: './facility-link.component.html',
    styleUrls: ['./facility-link.component.scss'],
})
export class FacilityLinkComponent {
    @Input() public facility: FacilityDto;
    @Input() public facilityId: number;

    public get linkToCapsuleId(): number {
        if (this.facilityId) {
            return this.facilityId;
        } else if (this.facility.id) {
            return this.facility.id;
        } else {
            return -1;
        }
    }
}
