import {Component, HostBinding, OnInit} from '@angular/core';
import {CapsuleDashboardOverviewDto, FacilityDashboardOverviewDto} from '@api';
import {DashboardService} from '@common/service/dashboard.service';
import {ErrorHandlingService} from '@common/service/error-handling.service';
import {ChartData, ChartOptions} from 'chart.js';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    @HostBinding('class.u-main-container')
    public mainContainer = true;

    public capsulesInShipmentData: ChartData;
    public capsulesByEventData: ChartData;
    public capsulesByShipmentStatusData: ChartData;
    public chartOptions: ChartOptions;

    public isLoading = true;
    public reducedPackageWaste: number;
    public numberOfShipments: number;
    public missingCapsules: CapsuleDashboardOverviewDto[] = [];
    public lowestStockedFacilities: FacilityDashboardOverviewDto[] = [];

    private readonly DHL_YELLOW = '#FFCC00';
    private readonly LABEL_COLOR = '#495057';
    private readonly GRID_COLOR = '#ebedef';

    public constructor(
        private readonly dashboardService: DashboardService,
        private readonly errorHandlingService: ErrorHandlingService,
    ) {}

    public async ngOnInit(): Promise<void> {
        const capsulesInShipments$ = this.dashboardService.countShipmentsbyType();
        const dashboardOverview$ = this.dashboardService.getDashboardOverview();

        forkJoin([capsulesInShipments$, dashboardOverview$])
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe(([capsulesInShipmentData, dashboardOverview]) => {
                this.capsulesInShipmentData = this.transformToChartData(
                    'DASHBOARD-CAPSULES-PER-SHIPMENT-TYPE-LABEL',
                    capsulesInShipmentData,
                );
                this.capsulesByEventData = this.transformToChartData(
                    'DASHBOARD-CAPSULES-PER-EVENT-LABEL',
                    dashboardOverview.capsulesByEvent,
                );
                this.capsulesByShipmentStatusData = this.transformToChartData(
                    'DASHBOARD-CAPSULES-PER-SHIPMENT-STATUS-LABEL',
                    dashboardOverview.capsulesByShipmentStatus,
                );
                this.reducedPackageWaste = dashboardOverview.reducedPackageWaste;
                this.numberOfShipments = dashboardOverview.numberOfShipments;
                this.missingCapsules = dashboardOverview.missingCapsules;
                this.lowestStockedFacilities = dashboardOverview.lowestStockedFacilities;
            });

        this.chartOptions = this.createChartOptions();
    }

    private transformToChartData(labelKey: string, capsulesPerEvent: {[key: string]: number}): ChartData {
        const eventNames = Object.keys(capsulesPerEvent);
        const capsuleCount = Object.values(capsulesPerEvent);

        return {
            labels: eventNames,
            datasets: [
                {
                    label: this.errorHandlingService.translateKey(labelKey),
                    backgroundColor: [this.DHL_YELLOW],
                    yAxisID: 'y',
                    data: capsuleCount,
                },
            ],
        };
    }

    private createChartOptions(): ChartOptions {
        return {
            plugins: {
                legend: {
                    labels: {
                        color: this.LABEL_COLOR,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: this.LABEL_COLOR,
                    },
                    grid: {
                        color: this.GRID_COLOR,
                    },
                },
                y: {
                    display: true,
                    position: 'left',
                    grid: {
                        color: this.GRID_COLOR,
                    },
                },
            },
        };
    }
}
