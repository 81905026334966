import {NAVIGATION} from '@common/constant/navigation';

export class Url {
    public static loginUrl = `/${NAVIGATION.Root}`;
    public static userUrl = `/${NAVIGATION.User}`;
    public static dashboardUrl = `/${NAVIGATION.Dashboard}`;
    public static customerScanUrl = `/${NAVIGATION.Scan}/${NAVIGATION.Customer}`;
    public static servicePointScanUrl = `/${NAVIGATION.Scan}/${NAVIGATION.ServicePoint}`;
    public static facilityUrl = `/${NAVIGATION.Facility}`;
    public static facilityManagementUrl = `/${NAVIGATION.Facility}/${NAVIGATION.Management}`;
    public static shipmentUrl = `/${NAVIGATION.Shipment}`;
    public static consigneeCycleManagement = `/${NAVIGATION.Consignee}`;
    public static capsuleUrl = `/${NAVIGATION.Capsule}`;
    public static capsuleManagementUrl = `/${NAVIGATION.Capsule}/${NAVIGATION.Management}`;
    public static apiManagementUrl = `/${NAVIGATION.ApiManagement}`;
    public static transfershipmentScanUrl = `/${NAVIGATION.Scan}/${NAVIGATION.TransfershipmentScan}`;
    public static userManagementUrl = `/${NAVIGATION.User}/${NAVIGATION.Management}`;
}
