<div class="content-container" fxLayout="column" fxLayoutGap="20px" [handleLoading]="isLoading">
  <h1>{{ 'DASHBOARD-TITLE' | translate }}</h1>
  <div class="content-area" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px"
       fxLayoutAlign.gt-sm="start start">
    <div class="margin-top" fxFlex="100%" fxFlex.gt-sm="50%">
      <h2>{{ 'DASHBOARD-CAPSULES-PER-SHIPMENT-TYPE-TITLE' | translate }}</h2>
      <p-chart type="bar" [data]="capsulesInShipmentData" [options]="chartOptions" width="100%"
               height="300px"></p-chart>
    </div>
    <div class="margin-top" fxFlex="100%" fxFlex.gt-sm="50%">
      <h2>{{ 'DASHBOARD-MAP-TITLE' | translate }}</h2>
      <app-map></app-map>
    </div>
  </div>
  <div class="content-area" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px"
       fxLayoutAlign.gt-sm="start start">
    <div class="margin-top" fxFlex="100%" fxFlex.gt-sm="50%">
      <h2>{{ 'DASHBOARD-CAPSULES-PER-EVENT-TITLE' | translate }}</h2>
      <p-chart type="bar" [data]="capsulesByEventData" [options]="chartOptions" width="100%"
               height="300px">
      </p-chart>
    </div>
    <div class="margin-top" fxFlex="100%" fxFlex.gt-sm="50%">
      <h2>{{ 'DASHBOARD-CAPSULES-PER-SHIPMENT-STATUS-TITLE' | translate }}</h2>
      <p-chart type="bar" [data]="capsulesByShipmentStatusData" [options]="chartOptions" width="100%"
               height="300px">
      </p-chart>
    </div>
    <div class="margin-top" fxFlex="100%" fxFlex.gt-sm="50%">
      <h2>{{ 'DASHBOARD-CURRENT-NUMBERS-TITLE' | translate }}</h2>
      <p-card>
        <div>
          <h3>{{ 'REDUCED_CARDBOARD_AMOUNT' | translate }}</h3>
          <p>{{ reducedPackageWaste ?? '?' }} Kg</p>
        </div>
        <div>
          <h3>{{ 'DASHBOARD-CURRENT-ACTIVE-SHIPMENT-COUNT' | translate }}</h3>
          <p>{{ numberOfShipments ?? '?' }} </p>
        </div>
      </p-card>
    </div>
  </div>
  <div class="content-area" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px"
       fxLayoutAlign.gt-sm="start start">
    <div class="margin-top" fxFlex="100%" fxFlex.gt-sm="33%">
      <h2>{{ 'DASHBOARD-MISSING-CAPSULES-TITLE' | translate }}</h2>
      <p-table [value]="missingCapsules" selectionMode="single" [paginator]="true" [rows]="10"
               [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-sm"
               [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} of {totalRecords} capsules">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="dhlCapsuleId" pResizableColumn>{{ 'CAPSULE-ID' | translate }}
              <p-columnFilter type="text" field="dhlCapsuleId" display="menu"></p-columnFilter>
            </th>
            <th pSortableColumn="type" pResizableColumn>{{ 'CAPSULE-TYPE' | translate }}
              <p-columnFilter type="text" field="type" display="menu"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-capsuleDto>
          <tr>
            <td>
              <capsule-link [capsule]="capsuleDto">{{ capsuleDto.dhlCapsuleId }}</capsule-link>
            </td>
            <td> {{ capsuleDto.typeName }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="margin-top" fxFlex="100%" fxFlex.gt-sm="66%">
      <h2>{{ 'DASHBOARD-LOWEST-STOCKED-FACILITIES-TITLE' | translate }}</h2>
      <p-table [value]="lowestStockedFacilities" selectionMode="single" [paginator]="true" [rows]="10"
               [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-sm"
               [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} of {totalRecords} facilities">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="dhlFacilityId" pResizableColumn>{{ 'FACILITY-ID' | translate }}
              <p-columnFilter type="text" field="dhlFacilityId" display="menu"></p-columnFilter>
            <th pSortableColumn="name" pResizableColumn>{{ 'FACILITY-NAME' | translate }}
              <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
            </th>
            <th pSortableColumn="stock" pResizableColumn>{{ 'FACILITY-STOCK' | translate }}
              <p-columnFilter type="text" field="stock" display="menu"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-facilityDto>
          <tr>
            <td>
              <facility-link [facilityId]="facilityDto.facilityId">{{ facilityDto.dhlFacilityId }}</facility-link>
            </td>
            <td> {{ facilityDto.facilityName }}</td>
            <td> {{ facilityDto.capsuleCount }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
