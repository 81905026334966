<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Popup</title>
    <link rel="stylesheet">
</head>
<body>
<div id="map" class="map"></div>
<div id="popup" class="ol-popup">
    <a href="#" id="popup-closer" class="ol-popup-closer"></a>
    <div id="popup-content"></div>
</div>
</body>
</html>
