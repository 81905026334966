import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {AccessControlService} from '@common/service/auth.service.access-control-service';
import {Observable} from 'rxjs';
import {NAVIGATION} from '@common/constant/navigation';

@Injectable({
    providedIn: 'root',
})
export class CapabilitiesGuard implements CanActivate, CanActivateChild {

    public constructor(
        private readonly router: Router,
        private readonly accessControlService: AccessControlService,
    ) {
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

        if (this.accessControlService.validateCapabilities(route.data.capabilities)) {
            return true;
        }

        // navigate to ROOT if no access is allowed
        return this.router.parseUrl(NAVIGATION.Root);
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }
}
