import {Injectable} from '@angular/core';
import {AuthService} from '@common/service/auth.service';
import {Router} from '@angular/router';
import {Url} from '@common/constant/url';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {

    public constructor(
        public readonly authService: AuthService,
        private readonly router: Router,
    ) {
    }

    public urlToHome(): string {
        let url = Url.capsuleUrl;
        if (this.authService.isAdmin()) {
            url = Url.dashboardUrl;
        } else if (this.authService.isSelectedFacilityServiceCenter || this.authService.isSelectedFacilityServicePoint) {
            url = Url.servicePointScanUrl;
        } else if (this.authService.isSelectedFacilityCustomer) {
            url = Url.customerScanUrl;
        }

        return url;
    }

    public async navigateToHome(): Promise<void> {
        const user = this.authService.getCurrentUser();
        if (user.admin) {
            await this.router.navigateByUrl(Url.dashboardUrl);
            return;
        }

        await this.router.navigateByUrl(Url.facilityUrl);
    }


}
