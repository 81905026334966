import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FacilityMapDto} from '@api';
import {FacilityService} from '@common/service/facility.service';
import {MapBrowserEvent} from 'ol';
import Feature, {FeatureLike} from 'ol/Feature';
import {Point} from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import {fromLonLat} from 'ol/proj';
import {OSM} from 'ol/source';
import VectorSource from 'ol/source/Vector';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import View from 'ol/View';

const COLOR_ORANGE = 30;
const COLOR_GREEN = 10;
const COLOR_RED = 20;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
    private map: Map;
    private overlay: Overlay;

    public constructor(private readonly facilityService: FacilityService) {}

    public ngOnInit(): void {
        this.setupOverlay();
        this.setupMap();
        this.loadFacilitiesAndDisplay();
    }

    private setupOverlay(): void {
        const container = document.getElementById('popup') as HTMLElement;
        const closer = document.getElementById('popup-closer') as HTMLElement;

        this.overlay = new Overlay({
            element: container,
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });

        closer.onclick = () => {
            this.overlay.setPosition(undefined);
            closer.blur();
            return false;
        };
    }

    private setupMap(): void {
        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            overlays: [this.overlay],
            target: 'map',
            view: new View({
                center: fromLonLat([4.89707, 52.377956]),
                zoom: 4,
                maxZoom: 18,
            }),
        });

        this.map.on('pointermove', (evt: MapBrowserEvent<MouseEvent>) => this.handlePointerMove(evt));
    }

    private loadFacilitiesAndDisplay(): void {
        this.facilityService.getAllFacilitiesMapOverview().subscribe((facilities) => {
            const features = facilities.map((facility) => this.createFeature(facility));
            this.displayFeatures(features);
        });
    }

    private createFeature(facility: FacilityMapDto): Feature {
        const coordinates = fromLonLat([facility.longitude ?? 0, facility.latitude ?? 0]);
        return new Feature({
            geometry: new Point(coordinates),
            color: this.determineColor(facility.stockCount),
            text: `${facility.name ?? ''}: ${facility.stockCount ?? 0}`,
        });
    }

    private displayFeatures(features: Feature[]): void {
        const vectorSource = new VectorSource({features, wrapX: false});
        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: (feature: FeatureLike) => this.getStyleForFeature(feature),
        });
        this.map.addLayer(vectorLayer);
    }

    private getStyleForFeature(feature: FeatureLike): Style {
        const color = feature.get('color');
        let fillColor: string;

        switch (color) {
            case COLOR_GREEN:
                fillColor = '#008000';
                break;
            case COLOR_ORANGE:
                fillColor = '#FFA500';
                break;
            case COLOR_RED:
            default:
                fillColor = '#FF0000';
                break;
        }

        return new Style({
            image: new CircleStyle({
                radius: 8,
                fill: new Fill({color: fillColor}),
                stroke: new Stroke({color: '#D3D3D3', width: 1}),
            }),
        });
    }

    private handlePointerMove(evt: MapBrowserEvent<MouseEvent>): void {
        const featuresInformation = this.map.getFeaturesAtPixel(evt.pixel);
        if (featuresInformation.length > 0) {
            const properties = featuresInformation[0].getProperties();
            const content = document.getElementById('popup-content') as HTMLElement;
            content.innerText = properties.text;
            this.overlay.setPosition(evt.coordinate);
        } else {
            this.overlay.setPosition(undefined);
        }
    }

    private determineColor(stockCount: number): number {
        switch (true) {
            case stockCount > 5:
                return COLOR_GREEN;
            case stockCount > 2:
                return COLOR_ORANGE;
            default:
                return COLOR_RED;
        }
    }
}
