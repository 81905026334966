<div class="navbar-container flex justify-content-between align-items-center p-3 bg-primary"
     *ngIf="currentUser$ | async as currentUser ">
    <div class="branding flex align-items-center">
        <a (click)="goToDashboard()" class="navbar-link flex align-items-center">
            <img
                    alt="DHL logo"
                    class="max-width-100px"
                    src="assets/dhl-logo.svg"
            />
            <span class="title m-3 flex align-items-center">{{'PROJECT-NAME' | translate}}</span>
        </a>
    </div>
    <div class="flex align-items-center">
        <form [formGroup]="selectedFacilityFormGroup">
            <p-dropdown id="navbar-dropdown" class="mr-4" (onChange)="setSelectedFacility()" [options]="facilities"
                        formControlName="facility" optionLabel="dhlFacilityId">
                <ng-template let-item pTemplate="item">
                    {{item.dhlFacilityId}}
                </ng-template>

                <ng-template let-item pTemplate="selectedItem">
                    <i class="pi pi-building"></i>
                    {{item.dhlFacilityId}}
                </ng-template>
            </p-dropdown>
        </form>
        <button (click)="goToUserPage()" class="navbar-button mr-4 p-button-link" icon="pi pi-user"
                label="{{currentUser.name}}" pButton
                pRipple></button>
        <button (click)="logout()" class="navbar-button mr-4 p-button-link" icon="pi pi-sign-out" pButton></button>
    </div>
</div>
