import {Injectable} from '@angular/core';
import {Message} from 'primeng/api';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private readonly alertSubject = new BehaviorSubject<Message | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() {}

    public getAlerts(): Observable<Message | null> {
        return this.alertSubject.asObservable();
    }

    public showAlert(message: Message): void {
        this.alertSubject.next(message);
    }

    public clearAlert(): void {
        this.alertSubject.next(null);
    }
}
