import {Component, OnInit} from '@angular/core';
import {AlertService} from '@common/service/alert.service';
import {Message} from 'primeng/api';

@Component({
    template: ` <p-messages [(value)]="msgs" [enableService]="false"></p-messages>`,
    selector: 'app-alert-message',
})
export class AlertMessageComponent implements OnInit {
    public msgs: Message[] = [];

    public constructor(private alertService: AlertService) {}

    public async ngOnInit(): Promise<void> {
        this.alertService.getAlerts().subscribe((message: Message | null) => {
            if (message) {
                this.msgs = this.getMessages(message, false);
            }
        });
    }

    public getMessages(message: Message, showMultipleAlertsAtTheSameTime: boolean): Message[] {
        if (showMultipleAlertsAtTheSameTime) {
            return (this.msgs = [...this.msgs, message]);
        } else {
            return [message];
        }
    }
}

export enum AlertType {
    ERROR = 'Error',
    WARNING = 'Warn',
    INFO = 'Info',
    SUCCESS = 'Success',
}
