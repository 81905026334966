import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertMessageComponent} from '@common/component/alert-message/alert-message.component';
import {CapsuleLinkComponent} from '@common/component/capsule-link/capsule-link.component';
import {FacilityLinkComponent} from '@common/component/facility-link/facility-link.component';
import {SubNavbarComponent} from '@common/component/sub-navbar/sub-navbar.component';
import {LoadingDirective} from '@common/directive/loading.directive';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {RippleModule} from 'primeng/ripple';
import {TabMenuModule} from 'primeng/tabmenu';
import {NavbarComponent} from './component/navbar/navbar.component';

@NgModule({
    declarations: [
        NavbarComponent,
        SubNavbarComponent,
        AlertMessageComponent,
        CapsuleLinkComponent,
        FacilityLinkComponent,
        LoadingDirective,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RippleModule,
        ButtonModule,
        TabMenuModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        MenubarModule,
        MessagesModule,
    ],
    exports: [
        NavbarComponent,
        SubNavbarComponent,
        TranslateModule,
        AlertMessageComponent,
        FacilityLinkComponent,
        CapsuleLinkComponent,
        LoadingDirective,
    ],
})
export class ClfCommonModule {}
