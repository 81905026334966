import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorResponse} from '@api';
import {AuthStore} from '@common/store/auth.store';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NAVIGATION} from '../constant/navigation';

const unauthorizedCode = 401;
const forbiddenCode = 403;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public constructor(private readonly router: Router, private readonly authStore: AuthStore) {}

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(async (error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === unauthorizedCode || error.status === forbiddenCode) {
                        this.authStore.reset();
                        await this.router.navigateByUrl(NAVIGATION.Root);
                    } else if (!this.isErrorResponse(error.error)) {
                        // eslint-disable-next-line @typescript-eslint/no-throw-literal
                        throw new HttpErrorResponse({
                            ...error,
                            url: error.url ?? undefined,
                            error: {
                                errorCode: ErrorResponse.ErrorCodeEnum.UNKNOWN,
                            } as ErrorResponse,
                        });
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-throw-literal
                        throw error;
                    }
                }

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const unknownError = error as any;
                const status = unknownError?.status || unknownError?.statusCode;
                const statusText = unknownError?.statusText || unknownError?.message;

                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw new HttpErrorResponse({
                    error: {errorCode: ErrorResponse.ErrorCodeEnum.UNKNOWN} as ErrorResponse,
                    headers:
                        unknownError?.headers instanceof HttpHeaders
                            ? unknownError.headers
                            : undefined,
                    status: typeof status === 'number' ? status : undefined,
                    statusText: typeof statusText === 'string' ? statusText : undefined,
                    url: typeof unknownError?.url === 'string' ? unknownError.url : undefined,
                });
            }),
        );
    }

    private isErrorResponse(object: unknown): object is ErrorResponse {
        return object != null && typeof object === 'object' && 'errorCode' in object;
    }
}
