import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MenuCapabilities} from '@common/constant/capabilities';
import {NAVIGATION} from '@common/constant/navigation';
import {AdminGuard} from '@common/guard/admin.guard';
import {AuthenticationGuard} from '@common/guard/authentication.guard';
import {CapabilitiesGuard} from '@common/guard/capabilities.guard';

const publicRoutes: Routes = [
    {
        path: NAVIGATION.Root,
        loadChildren: async () => (await import('./login-module/login.module')).LoginModule,
    },
];

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: NAVIGATION.Dashboard,
    },
    {
        path: '',
        canActivate: [AuthenticationGuard],
        canActivateChild: [CapabilitiesGuard],
        children: [
            {
                path: NAVIGATION.Dashboard,
                loadChildren: async () =>
                    (await import('./dashboard-module/dashboard.module')).DashboardModule,
            },
            {
                path: NAVIGATION.Scan,
                children: [
                    {
                        path: NAVIGATION.TransfershipmentScan,
                        data: {
                            capabilities: MenuCapabilities.TRANSFER_SHIPMENT,
                        },
                        loadChildren: async () =>
                            (
                                await import(
                                    './scan/transfer-shipment-scan-module/transfershipment-scan.module'
                                )
                            ).TransfershipmentScanModule,
                    },
                    {
                        path: NAVIGATION.Customer,
                        data: {
                            capabilities: MenuCapabilities.CUSTOMER_SCAN,
                        },
                        loadChildren: async () =>
                            (await import('./scan/customer-scan-module/customer-scan.module'))
                                .CustomerScanModule,
                    },
                    {
                        path: NAVIGATION.ServicePoint,
                        data: {
                            capabilities: MenuCapabilities.SERVICE_POINT_SCAN,
                        },
                        // eslint-disable-next-line max-len
                        loadChildren: async () =>
                            (
                                await import(
                                    './scan/service-point-scan-module/service-point-scan.module'
                                )
                            ).ServicePointScanModule,
                    },
                ],
            },
            {
                path: NAVIGATION.Facility,
                loadChildren: async () =>
                    (await import('./facility-module/facility.module')).FacilityModule,
            },
            {
                path: NAVIGATION.Shipment,
                loadChildren: async () =>
                    (await import('./shipment-module/shipment.module')).ShipmentModule,
            },
            {
                path: NAVIGATION.Consignee,
                loadChildren: async () =>
                    (await import('./consignee-module/consignee.module')).ConsigneeModule,
            },
            {
                path: NAVIGATION.Capsule,
                loadChildren: async () =>
                    (await import('./capsule-module/capsule.module')).CapsuleModule,
            },
            {
                path: NAVIGATION.User,
                loadChildren: async () => (await import('./user-module/user.module')).UserModule,
            },
            {
                path: NAVIGATION.ApiManagement,
                canActivate: [AdminGuard],
                loadChildren: async () => (await import('./api-module/api.module')).ApiModule,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot([...publicRoutes, ...routes])],
    exports: [RouterModule],
})
export class AppRoutingModule {}
