import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsubscriberService implements OnDestroy {

  private _subscription: Subscription = new Subscription();

  public set subscription(sub: Subscription) {
    this._subscription.add(sub);
  }

  public ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
