import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CapsuleApiService } from './api/capsuleApi.service';
import { CapsuleTypeApiService } from './api/capsuleTypeApi.service';
import { DashboardApiService } from './api/dashboardApi.service';
import { DhlImporterApiService } from './api/dhlImporterApi.service';
import { ErrorService } from './api/error.service';
import { ExporterApiService } from './api/exporterApi.service';
import { FacilityApiService } from './api/facilityApi.service';
import { IotApiService } from './api/iotApi.service';
import { PropertiesApiService } from './api/propertiesApi.service';
import { ShipmentApiService } from './api/shipmentApi.service';
import { UserService } from './api/user.service';
import { ZoneApiService } from './api/zoneApi.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CapsuleApiService,
    CapsuleTypeApiService,
    DashboardApiService,
    DhlImporterApiService,
    ErrorService,
    ExporterApiService,
    FacilityApiService,
    IotApiService,
    PropertiesApiService,
    ShipmentApiService,
    UserService,
    ZoneApiService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
