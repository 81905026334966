/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.411+20240807T073820Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FacilityDetailShipmentOverviewDto { 
    dhlPieceId: string;
    dhlCapsuleId: string;
    capsuleTypeName: string;
    status: FacilityDetailShipmentOverviewDto.StatusEnum;
    expectedDeliveryTime: Date;
    created: Date;
    lastUpdateDatetime: Date;
    capsuleStatuses: Array<FacilityDetailShipmentOverviewDto.CapsuleStatusesEnum>;
}
export namespace FacilityDetailShipmentOverviewDto {
    export type StatusEnum = 'WAITING_FOR_VALIDATION' | 'WAITING_FOR_DELIVERY' | 'WAITING_FOR_PICKUP' | 'IN_SHIPMENT' | 'DHL_CHECKPOINT_SA' | 'DHL_CHECKPOINT_PU' | 'DHL_CHECKPOINT_CI' | 'DHL_CHECKPOINT_AR' | 'DHL_CHECKPOINT_PL' | 'DHL_CHECKPOINT_AF' | 'DHL_CHECKPOINT_HN' | 'DHL_CHECKPOINT_CC' | 'DHL_CHECKPOINT_WC' | 'DHL_CHECKPOINT_OK' | 'RETURN_TO_SERVICECENTER' | 'FINISHED' | 'NOT_FOUND' | 'CANCELLED' | 'UNKNOWN';
    export const StatusEnum = {
        WAITINGFORVALIDATION: 'WAITING_FOR_VALIDATION' as StatusEnum,
        WAITINGFORDELIVERY: 'WAITING_FOR_DELIVERY' as StatusEnum,
        WAITINGFORPICKUP: 'WAITING_FOR_PICKUP' as StatusEnum,
        INSHIPMENT: 'IN_SHIPMENT' as StatusEnum,
        DHLCHECKPOINTSA: 'DHL_CHECKPOINT_SA' as StatusEnum,
        DHLCHECKPOINTPU: 'DHL_CHECKPOINT_PU' as StatusEnum,
        DHLCHECKPOINTCI: 'DHL_CHECKPOINT_CI' as StatusEnum,
        DHLCHECKPOINTAR: 'DHL_CHECKPOINT_AR' as StatusEnum,
        DHLCHECKPOINTPL: 'DHL_CHECKPOINT_PL' as StatusEnum,
        DHLCHECKPOINTAF: 'DHL_CHECKPOINT_AF' as StatusEnum,
        DHLCHECKPOINTHN: 'DHL_CHECKPOINT_HN' as StatusEnum,
        DHLCHECKPOINTCC: 'DHL_CHECKPOINT_CC' as StatusEnum,
        DHLCHECKPOINTWC: 'DHL_CHECKPOINT_WC' as StatusEnum,
        DHLCHECKPOINTOK: 'DHL_CHECKPOINT_OK' as StatusEnum,
        RETURNTOSERVICECENTER: 'RETURN_TO_SERVICECENTER' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        NOTFOUND: 'NOT_FOUND' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        UNKNOWN: 'UNKNOWN' as StatusEnum
    };
    export type CapsuleStatusesEnum = 'MISSING' | 'CLEAN' | 'LOST' | 'BLE_LOST' | 'DIRTY' | 'BROKEN' | 'DECOMMISSIONED';
    export const CapsuleStatusesEnum = {
        MISSING: 'MISSING' as CapsuleStatusesEnum,
        CLEAN: 'CLEAN' as CapsuleStatusesEnum,
        LOST: 'LOST' as CapsuleStatusesEnum,
        BLELOST: 'BLE_LOST' as CapsuleStatusesEnum,
        DIRTY: 'DIRTY' as CapsuleStatusesEnum,
        BROKEN: 'BROKEN' as CapsuleStatusesEnum,
        DECOMMISSIONED: 'DECOMMISSIONED' as CapsuleStatusesEnum
    };
}