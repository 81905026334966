/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.411+20240807T073820Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FacilityDto } from './facilityDto';

export interface GetUserDto { 
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    facilities: Array<FacilityDto>;
}