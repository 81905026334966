import {Injectable} from '@angular/core';
import {Capabilities, MenuCapabilities} from '@common/constant/capabilities';
import {AuthService} from '@common/service/auth.service';
import {FacilityStore} from '@common/store/facility.store';

@Injectable({
    providedIn: 'root',
})
export class AccessControlService {
    public constructor(public readonly authService: AuthService, private readonly facilityStore: FacilityStore) {}

    public getCurrentCapabilities(): Capabilities[] {
        const currentCapabilities: Capabilities[] = [];
        const selectedFacility = this.facilityStore.getSelectedFacility();
        if (selectedFacility?.capabilities) {
            selectedFacility.capabilities
                .map((it) => this.stringToCapabilityEnum(it.capability))
                .forEach((capability) => {
                    currentCapabilities.push(capability);
                });
        }
        return currentCapabilities;
    }

    public validateCapabilities(requiredCapabilities: Capabilities[]): boolean {
        const currentCapabilities = this.getCurrentCapabilities();

        //If no capabilities are required, user is allowed to load the component
        if (!requiredCapabilities) {
            return true;
        }

        if (currentCapabilities && requiredCapabilities) {
            if (requiredCapabilities.every((capability) => currentCapabilities.includes(capability))) {
                return true;
            }
        }
        return false;
    }

    public dashboard(): boolean {
        return this.authService.isAdmin();
    }

    public scanMenu(): boolean {
        return this.transfershipmentScan() || this.customerScan() || this.servicePointScan();
    }

    public transfershipmentScan(): boolean {
        return this.validateCapabilities(MenuCapabilities.TRANSFER_SHIPMENT);
    }

    public customerScan(): boolean {
        return this.validateCapabilities(MenuCapabilities.CUSTOMER_SCAN);
    }

    public servicePointScan(): boolean {
        return this.validateCapabilities(MenuCapabilities.SERVICE_POINT_SCAN);
    }

    public isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    private stringToCapabilityEnum(str: string): Capabilities {
        switch (str) {
            case 'BASIC_CAPABILITIES':
                return Capabilities.BASIC_CAPABILITIES;
            case 'START_CONSIGNEE_CYCLE':
                return Capabilities.START_CONSIGNEE_CYCLE;
            case 'END_CONSIGNEE_CYCLE':
                return Capabilities.END_CONSIGNEE_CYCLE;
            case 'START_CONSIGNEE_CYCLE_TRANSFERSHIPMENT':
                return Capabilities.START_CONSIGNEE_CYCLE_TRANSFERSHIPMENT;
            case 'START_CONSIGNEE_CYCLE_RETURNSHIPMENT':
                return Capabilities.START_CONSIGNEE_CYCLE_RETURNSHIPMENT;
            case 'SERVICE_CENTER_SCAN':
                return Capabilities.SERVICE_CENTER_SCAN;
            case 'ALLOW_SHIPMENT_CREATION_WITHOUT_VALIDATION':
                return Capabilities.ALLOW_SHIPMENT_CREATION_WITHOUT_VALIDATION;
            default:
                throw new Error(`Invalid string value for MyEnum: ${str}`);
        }
    }
}
