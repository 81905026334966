import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorResponse} from '@api';
import {AlertType} from '@common/component/alert-message/alert-message.component';
import {AlertService} from '@common/service/alert.service';
import {LoadingService} from '@common/service/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {Message} from 'primeng/api';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import ErrorCodeEnum = ErrorResponse.ErrorCodeEnum;

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlingService {
    public constructor(
        private alertService: AlertService,
        private translateService: TranslateService,
        private loadingService: LoadingService,
    ) {}

    private prepareAlert(alertType: AlertType, alertMessage: string): Message {
        return {severity: alertType.toLowerCase(), summary: alertType, detail: alertMessage};
    }

    public createSuccessAlert(successTranslationKey: string): void {
        const alert = this.prepareAlert(AlertType.SUCCESS, this.translateKey(successTranslationKey));
        this.alertService.showAlert(alert);
    }

    public createWarningAlert(warningTranslationKey: string): void {
        const alert = this.prepareAlert(AlertType.WARNING, this.translateKey(warningTranslationKey));
        this.alertService.showAlert(alert);
    }

    public createErrorAlert(errorTranslationKey: string): void {
        const alert = this.prepareAlert(AlertType.ERROR, this.translateKey(errorTranslationKey));
        this.alertService.showAlert(alert);
    }

    public translateKey(translationKey: string): string {
        return this.translateService.instant(translationKey);
    }

    public handleErrors<T>(observable: Observable<T>, options?: ErrorHandlingOptions): Subscription {
        this.loadingService.show();

        return observable
            .pipe(
                finalize(() => {
                    this.loadingService.hide();
                    if (options?.finalize) {
                        options.finalize();
                    }
                }),
            )
            .subscribe({
                next: (value) => {
                    if (options?.onSuccessTranslationKey) {
                        this.createSuccessAlert(options.onSuccessTranslationKey);
                    }
                    if (options?.onSuccess) {
                        options.onSuccess(value);
                    }
                },
                error: (httpErrorResponse: HttpErrorResponse) => {
                    const error: ErrorResponse | undefined = httpErrorResponse.error as ErrorResponse;

                    if (options?.onError) {
                        options.onError(error?.errorCode);
                    }

                    if (options?.onErrorTranslationKey) {
                        this.createErrorAlert(options.onErrorTranslationKey);
                    }

                    if (!options?.onError && !options?.onErrorTranslationKey) {
                        this.handleCommonErrors(error?.errorCode);
                    }
                },
            });
    }

    public handleCommonErrors(errorCode: ErrorResponse.ErrorCodeEnum | undefined): void {
        let messageKey: string;

        switch (errorCode) {
            case ErrorCodeEnum.CAPSULENOTFOUND:
                messageKey = 'ALERT-MESSAGE-ERROR-CAPSULE-NOT-FOUND';
                break;
            case ErrorCodeEnum.SHIPMENTNOTFOUND:
                messageKey = 'ALERT-MESSAGE-ERROR-SHIPMENT-NOT-FOUND-FOR-PIECE-ID';
                break;
            case ErrorCodeEnum.SHIPMENTALREADYEXISTS:
                messageKey = 'ALERT-MESSAGE-ERROR-SHIPMENT-ALREADY-EXISTS-FOR-PIECE-ID';
                break;
            case ErrorCodeEnum.STOCKNOTFOUND:
                messageKey = 'ALERT-MESSAGE-ERROR-STOCK-NOT-FOUND';
                break;
            case ErrorCodeEnum.PASSWORDREQUIREMENTSNOTMET:
                messageKey = 'ALERT-MESSAGE-ERROR-PASSWORD-REQUIREMENTS';
                break;
            case ErrorCodeEnum.PASSWORDINCORRECT:
                messageKey = 'ALERT-MESSAGE-ERROR-PASSWORD-INCORRECT';
                break;
            case ErrorCodeEnum.ASSETNOTFOUND:
                messageKey = 'ALERT-MESSAGE-ERROR-ASSET-NOT-FOUND';
                break;
            default:
                messageKey = 'ALERT-MESSAGE-ERROR-UNKNOWN';
        }

        return this.createErrorAlert(messageKey);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public forkJoinWithLoading<T extends [Observable<any>, ...Observable<any>[]]>(
        observables: T,
    ): Observable<{[K in keyof T]: T[K] extends Observable<infer U> ? U : never}> {
        this.loadingService.show();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return forkJoin(observables).pipe(finalize(() => this.loadingService.hide())) as any;
    }
}

export interface ErrorHandlingOptions {
    finalize?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: (data: any) => void;
    onSuccessTranslationKey?: string;
    onError?: (error: ErrorResponse.ErrorCodeEnum) => void;
    onErrorTranslationKey?: string;
}
