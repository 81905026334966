import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {LoadingService} from '@common/service/loading.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[handleLoading]',
})
export class LoadingDirective implements OnInit, OnChanges, OnDestroy {
    @Input('handleLoading') public show = false;

    private spinnerElement: HTMLElement;
    private loadingSubscription: Subscription;

    public constructor(private el: ElementRef, private renderer: Renderer2, private loadingService: LoadingService) {}

    public ngOnInit(): void {
        this.createSpinner();
        this.loadingSubscription = this.loadingService.loading$.subscribe((isLoading: boolean) => {
            this.updateSpinner(isLoading || this.show);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.show) {
            this.updateSpinner(this.show);
        }
    }

    public ngOnDestroy(): void {
        this.loadingSubscription.unsubscribe();
    }

    private updateSpinner(show: boolean): void {
        if (show) {
            this.showSpinner();
        } else {
            this.hideSpinner();
        }
    }

    private createSpinner(): void {
        this.spinnerElement = this.renderer.createElement('div');
        this.renderer.addClass(this.spinnerElement, 'loading-spinner');
        this.renderer.appendChild(this.el.nativeElement, this.spinnerElement);
    }

    private showSpinner(): void {
        this.renderer.setStyle(this.spinnerElement, 'display', 'block');
        this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.5');
    }

    private hideSpinner(): void {
        this.renderer.setStyle(this.spinnerElement, 'display', 'none');
        this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    }
}
