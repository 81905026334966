import {Injectable} from '@angular/core';
import {DashboardApiService, DashboardOverviewDto} from '@api';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    public constructor(private dashBoardApi: DashboardApiService) {}

    public countShipmentsbyType(): Observable<{[p: string]: number}> {
        return this.dashBoardApi.countShipmentsType();
    }

    public getDashboardOverview(): Observable<DashboardOverviewDto> {
        return this.dashBoardApi.getDashboardOverview();
    }
}
