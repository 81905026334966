import { Injectable } from '@angular/core';
import { CurrentUserDto } from '@api';
import { write } from '@common/util/store-write';
import { createStore, select, withProps } from '@ngneat/elf';

interface AuthStoreState {
    user?: CurrentUserDto;
}


@Injectable({
    providedIn: 'root'
})
export class AuthStore {
    private readonly store$ = createStore(
        {
            name: 'auth',
        },
        withProps<AuthStoreState>({}),
    );

    public readonly user$ = this.store$.pipe(select((state) => state.user));

    public setCurrentUser(user?: CurrentUserDto): void {
        this.store$.update(
            write((state) => {
                state.user = user;
            }),
        );
    }

    public reset(): void {
        this.setCurrentUser();
    }
}
