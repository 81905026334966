import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from '@common/service/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate {

    public constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
    ) {
    }

    public async canActivate(): Promise<boolean | UrlTree> {
        return this.authService.isAdmin();
    }
}
