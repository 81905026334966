import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {FacilityService} from '@common/service/facility.service';
import {FacilityStore} from '@common/store/facility.store';
import {NAVIGATION} from '../constant/navigation';
import {AuthService} from '../service/auth.service';
import {AuthStore} from '../store/auth.store';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {

    public constructor(
        private readonly router: Router,
        private readonly authStore: AuthStore,
        private readonly authService: AuthService,
        private readonly facilityStore: FacilityStore,
        private readonly facilityService: FacilityService,
    ) {
    }

    public async canActivate(): Promise<boolean | UrlTree> {
        const user = await firstValueFrom(this.authStore.user$);

        if (user == null) {
            try {
                await this.authService.refreshCurrentUser();
            } catch (e: unknown) {
                return this.router.parseUrl(NAVIGATION.Root);
            }
        }

        if (!this.facilityStore.getSelectedFacility()) {
            await this.facilityService.refreshSelectedFacility();
        }

        return true;
    }


}
