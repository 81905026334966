/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.411+20240807T073820Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IoTLocationDto } from './ioTLocationDto';
import { IoTMessagePropertiesDto } from './ioTMessagePropertiesDto';

export interface IoTMessageDto { 
    metaData: any;
    metaDataDate: Date;
    version: string;
    id: string;
    applicationId: string;
    deviceId: string;
    deviceTypeId: string;
    providerId: string;
    deviceAccessGroupId: string;
    accessGroups: Array<any>;
    deviceTimeStamp: number;
    ingestionTimestamp: number;
    location: IoTLocationDto;
    zones: Array<string>;
    properties: IoTMessagePropertiesDto;
    sensorMeasurements: any;
    eventProcessedUtcTime: Date;
    partitionId: number;
    eventEnqueuedUtcTime: Date;
    rid: string;
    self: string;
    etag: string;
    attachments: string;
    ts: number;
}