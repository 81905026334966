/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.411+20240807T073820Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EditFacilityDto { 
    facilityId: number;
    type: EditFacilityDto.TypeEnum;
    serviceAreaCode: string;
    name: string;
    countryCode: string;
}
export namespace EditFacilityDto {
    export type TypeEnum = 'F' | 'P' | 'C' | 'EC';
    export const TypeEnum = {
        F: 'F' as TypeEnum,
        P: 'P' as TypeEnum,
        C: 'C' as TypeEnum,
        EC: 'EC' as TypeEnum
    };
}