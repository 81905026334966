import { Component } from '@angular/core';
import { CurrentUserDto } from '@api';
import { AuthService } from '@common/service/auth.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {

    public currentUser$: Observable<CurrentUserDto | undefined>;

    public constructor(
        private readonly authService: AuthService,
    ) {
        this.currentUser$ = this.authService.currentUser$;
    }
}
