import { Injectable } from '@angular/core';
import { FacilityDto } from '@api';


@Injectable({
  providedIn: 'root',
})
export class FacilityStore {
  private _facility?: FacilityDto;

  public reset(): void {
    this.setSelectedFacility();
    this.setSelectedFacilityObject();
  }

  public setSelectedFacility(facility?: FacilityDto): void {
    this._facility = Object.freeze(facility);
    this.setSelectedFacilityObject(facility);
  }

  public getSelectedFacility(): FacilityDto | undefined {
    return this.getSelectedFacilityObject();
  }

  private setSelectedFacilityObject(facility?: FacilityDto): void {
    if (facility) {
      localStorage.setItem('selectedFacility', JSON.stringify(facility));
    } else {
      localStorage.removeItem('selectedFacility');
    }
  }

  public getSelectedFacilityObject(): FacilityDto | undefined {
    const selectedFacility = localStorage.getItem('selectedFacility');
    if (selectedFacility){
      return JSON.parse(selectedFacility);
    } else {
      return undefined;
    }
  }
}
