// eslint-disable-next-line no-shadow
export enum NAVIGATION {
    Dashboard = 'dashboard',
    Scan = 'scan',
    TransfershipmentScan = 'transfershipment-scan',
    Customer = 'customer',
    ServicePoint = 'service-point',
    Help = 'help',
    Facility = 'facility',
    Shipment = 'shipment',
    Consignee = 'consignee',
    Capsule = 'capsule',
    Management = 'management',
    ApiManagement = 'api-management',
    User = 'user',
    Root = '',
}
