/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.411+20240807T073820Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateShipmentDto { 
    dhlPieceId: string;
    dhlCapsuleId: string;
    facilityId: number;
    shipmentType: CreateShipmentDto.ShipmentTypeEnum;
}
export namespace CreateShipmentDto {
    export type ShipmentTypeEnum = 'DHL_SHIPMENT' | 'EXTERNAL_SHIPMENT' | 'DHL_RETURN_SHIPMENT' | 'DHL_TRANSFER_SHIPMENT';
    export const ShipmentTypeEnum = {
        DHLSHIPMENT: 'DHL_SHIPMENT' as ShipmentTypeEnum,
        EXTERNALSHIPMENT: 'EXTERNAL_SHIPMENT' as ShipmentTypeEnum,
        DHLRETURNSHIPMENT: 'DHL_RETURN_SHIPMENT' as ShipmentTypeEnum,
        DHLTRANSFERSHIPMENT: 'DHL_TRANSFER_SHIPMENT' as ShipmentTypeEnum
    };
}