export enum Capabilities {
    BASIC_CAPABILITIES = 'BASIC_CAPABILITIES',
    START_CONSIGNEE_CYCLE = 'START_CONSIGNEE_CYCLE',
    END_CONSIGNEE_CYCLE = 'END_CONSIGNEE_CYCLE',
    START_CONSIGNEE_CYCLE_TRANSFERSHIPMENT = 'START_CONSIGNEE_CYCLE_TRANSFERSHIPMENT',
    START_CONSIGNEE_CYCLE_RETURNSHIPMENT = 'START_CONSIGNEE_CYCLE_RETURNSHIPMENT',
    SERVICE_CENTER_SCAN = 'SERVICE_CENTER_SCAN',
    ALLOW_SHIPMENT_CREATION_WITHOUT_VALIDATION = 'ALLOW_SHIPMENT_CREATION_WITHOUT_VALIDATION',
}

export class MenuCapabilities {
    public static TRANSFER_SHIPMENT = [Capabilities.START_CONSIGNEE_CYCLE_TRANSFERSHIPMENT];
    public static CUSTOMER_SCAN = [Capabilities.START_CONSIGNEE_CYCLE];
    public static SERVICE_POINT_SCAN = [Capabilities.START_CONSIGNEE_CYCLE];
}
