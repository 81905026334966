import {Component, Input} from '@angular/core';
import {CapsuleDetailDto} from '@api';

@Component({
    selector: 'capsule-link',
    templateUrl: './capsule-link.component.html',
    styleUrls: ['./capsule-link.component.scss'],
})
export class CapsuleLinkComponent {
    @Input() public capsule: CapsuleDetailDto;
    @Input() public dhlCapsuleId: string;

    public get linkToCapsuleId(): string {
        if (this.dhlCapsuleId) {
            return this.dhlCapsuleId;
        } else if (this.capsule.dhlCapsuleId) {
            return this.capsule.dhlCapsuleId;
        } else {
            return '';
        }
    }
}
