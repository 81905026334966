import {Injectable} from '@angular/core';
import {
    CapabilityTypeDto,
    CreateFacilityDto,
    EditFacilityDto,
    FacilityApiService,
    FacilityDetailDto,
    FacilityDto,
    FacilityMapDto,
    ZoneApiService,
    ZoneDto,
} from '@api';
import {NoFacilitiesError} from '@common/errors/no-facilities-error';
import {FacilityStore} from '@common/store/facility.store';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FacilityService {
    public constructor(
        private readonly facilityApi: FacilityApiService,
        private readonly zoneApi: ZoneApiService,
        private readonly facilityStore: FacilityStore,
    ) {}

    public getFacility(facilityId: number): Observable<FacilityDto> {
        return this.facilityApi.getFacility(facilityId);
    }

    public getFacilityDetail(facilityId: number): Observable<FacilityDetailDto> {
        return this.facilityApi.getFacilityDetail(facilityId);
    }

    public getFacilitiesForCurrentUser(): Observable<FacilityDto[]> {
        return this.facilityApi.getFacilities();
    }

    public getAllFacilities(): Observable<FacilityDto[]> {
        return this.facilityApi.getAllFacilities();
    }

    public getAvailableZones(): Observable<ZoneDto[]> {
        return this.zoneApi.getAllZones();
    }

    public getAllServiceCenters(): Observable<FacilityDto[]> {
        return this.facilityApi.getFacilities([
            FacilityDto.TypeEnum.F.toString(),
            FacilityDto.TypeEnum.C.toString(),
            FacilityDto.TypeEnum.EC.toString(),
        ]);
    }

    public getAllFacilityTypes(): Observable<string[]> {
        return this.facilityApi.getAllFacilityTypes();
    }

    public addZoneToFacility(facility: FacilityDto, zoneDto: ZoneDto): Observable<FacilityDto> {
        return this.facilityApi.addZoneToFacility(facility.id, zoneDto);
    }

    public async refreshSelectedFacility(): Promise<void> {
        const selectedFacility = this.facilityStore.getSelectedFacilityObject();
        if (!selectedFacility) {
            throw new NoFacilitiesError();
        }
        this.getFacility(selectedFacility.id).subscribe((facility) => {
            this.facilityStore.setSelectedFacility(facility);
        });
    }

    public async getSelectedFacility(): Promise<FacilityDto | undefined> {
        return this.facilityStore.getSelectedFacility();
    }

    public editFacility(editFacilityDto: EditFacilityDto): Observable<FacilityDto> {
        return this.facilityApi.editFacility(editFacilityDto);
    }

    public unlinkZone(facilityId: number): Observable<void> {
        return this.facilityApi.unlinkZone(facilityId);
    }

    public updateCapability(facilityId: number, capabilities: CapabilityTypeDto[]): Observable<void> {
        return this.facilityApi.updateCapability(facilityId, capabilities);
    }

    public getCapabilities(): Observable<CapabilityTypeDto[]> {
        return this.facilityApi.getCapabilities();
    }

    public createFacility(createFacilityDto: CreateFacilityDto): Observable<FacilityDto> {
        return this.facilityApi.createFacility(createFacilityDto);
    }

    public getAllFacilitiesMapOverview(): Observable<FacilityMapDto[]> {
        return this.facilityApi.getAllFacilitiesMapOverview();
    }
}
