import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule, Routes} from '@angular/router';
import {ClfCommonModule} from '@common/clf-common.module';
import {CardModule} from 'primeng/card';
import {ChartModule} from 'primeng/chart';
import {SkeletonModule} from 'primeng/skeleton';
import {TableModule} from 'primeng/table';
import {MapComponent} from '../components/map/map.component';
import {DashboardComponent} from './dashboard.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
    },
];

@NgModule({
    declarations: [DashboardComponent, MapComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ClfCommonModule,
        ChartModule,
        CardModule,
        SkeletonModule,
        FlexLayoutModule,
        TableModule,
    ],
})
export class DashboardModule {}
